<template>
  <div>
    <error-alert/>
    <mheader/>
      <v-main class="pt-15 pb-5">
        <div class="mb-12">
        <banner class="my-3 px-1" :option="option"/>
        <brands class="mt-3 px-1" :options="options" imageWidth="65" imageHeight="65"/>
        <category class="mt-3"/>
      </div>
      <div>
        <action/>
      </div>
    </v-main>
  </div>
</template>
<script>
import { galleries } from './models/home'
import { mapState} from 'vuex'
import { viewbasket_products } from '../Basket/models/Basket'
import store from '@/store'
export default {
  components: {
    action: () => import ('./components/navbar/actionBar'),
    banner: () => import('./components/slider/banerSlider'),
    category: () => import('../categories/components/categoryCard'),
    brands: () => import ('./components/slider/brandsSlider.vue'),
    mheader: () => import ('./components/navbar/headerSearchIcon'),
    ErrorAlert: () => import ('@/components/alerts/error.alert.vue'),
  },
  computed: {
    ...mapState({
      loading_page: state => state.loading_page,
      blockProduct: state => state.home.blockProduct
    })
  },
  data:() => ({
    get_block: true,
    options: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 7,
      arrows: false,
      drag: true,
      pagination: false,
      waitForTransition: false,
      gap: '0.5rem',
      start: 0,
      // type: 'loop',
      focus: 'center',
      direction: 'rtl',
      breakpoints: {
        280: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 3,
        },
        340: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 4.2,
        },
        499: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 4.2,
        },
        500: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 5,
        },
        540: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 5,
        },
        800: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 5,
        },
        1024: {
          width  : '100%',
          gap: '0.5rem',
          perPage: 5,
        },
      },
    },
    option: {
      rewind : true,
      trimSpace: true,
      width: '100%',
      perPage: 1.5,
      arrows: false,
      drag: true,
      pagination: false,
      waitForTransition: false,
      gap: '0.5rem',
      start: 0,
      focus: 'center',
      direction: 'rtl',
      breakpoints: {
        280: {
          width  : '100%',
          perPage: 1,
        },
        340: {
          width  : '100%',
          perPage: 1.2,
        },
        341: {
          width  : '100%',
          perPage: 1.2,
        },
        499: {
          width  : '100%',
          perPage: 1.2,
          gap: '1rem',
        },
        500: {
          width  : '100%',
          perPage: 1.5,
        },
        540: {
          width  : '100%',
          perPage: 1.3,
        },
        670: {
          width  : '100%',
          perPage: 1.5,
        },
        671: {
          width  : '100%',
          perPage: 1.5,
        },
        800: {
          width  : '100%',
          perPage: 1.5,
        },
        801: {
          width  : '100%',
          perPage: 1.5,
        },
        1024: {
          width  : '100%',
          perPage: 2,
        }
      },
    },
  }),
  mounted () {
    store.dispatch('clear_search_result')
    localStorage.removeItem('subordinate')
    let config = JSON.parse(localStorage.getItem('config'))
    localStorage.removeItem('filter_search')
    store.dispatch('productDetail')
    viewbasket_products()
    if (this.blockProduct.length < 1) {
      galleries(config.mobile_slider_gallery,'banner')
      galleries(config.brands_gallery,'brands')
    }
  }
}
</script>